import React, { useState, useContext, useRef, useEffect } from "react";
import { ConsultationContext } from "../context/Consultation";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { navigate } from "gatsby";
import { capitalize, processLanguage } from "../util/functions";
import app from "gatsby-plugin-firebase-v9.0";
import i from "../staticAssets/user.png";
import {
  getFirestore,
  collection,
  orderBy,
  query,
  where,
  addDoc,
  serverTimestamp,
  limit,
} from "firebase/firestore";
import { getAstroDetail, messageSent } from "../services/apiServices";
import ConsultAlertModal from "../components/ConsultAlertModal";
import Send from "../staticAssets/Send.png";

function AstroMessage(props) {
  return (
    <div className="w-full flex flex-col items-start my-2">
      <div className="bg-white max-w-xs md:max-w-lg rounded-tr-xl rounded-br-xl rounded-bl-xl px-2 py-2 mx-2 shadow-sm text-sm md:text-base">
        <p className="flex flex-none">{`${props.data?.messageText}` ?? ""}</p>
      </div>
      <h3 className="text-gray-300 mx-4 my-1 text-xs">
        {props.data?.timestamp
          ? new Date(props.data?.timestamp?.toDate()).toLocaleString(
            "default",
            {
              month: "long",
              day: "numeric",
              hour: "numeric",
              hour12: true,
              minute: "numeric",
            }
          ) ?? ""
          : ""}
      </h3>
    </div>
  );
}

function UserMessage(props) {
  return (
    <div className="w-full flex flex-col items-end my-2">
      <div className="bg-[#FCECDC] max-w-xs md:max-w-lg rounded-tl-xl rounded-br-xl rounded-bl-xl px-2 py-2 mx-2 shadow-sm text-sm md:text-base">
        <p>{props.data?.messageText.replace("\n", <br />)}</p>
      </div>
      <h3 className="text-gray-300 mx-4 my-1 text-xs">
        {props.data?.timestamp
          ? new Date(props.data?.timestamp?.toDate()).toLocaleString(
            "default",
            {
              month: "long",
              day: "numeric",
              hour: "numeric",
              hour12: true,
              minute: "numeric",
            }
          ) ?? ""
          : "".split("T")[0].substring(1) ?? ""}
      </h3>
    </div>
  );
}

function Chat() {
  const db = getFirestore(app);
  const [detail, setdetail] = useState({});
  const dummyRef = useRef();
  const kRef = useRef();
  const [m, setm] = useState(0);
  const [s, sets] = useState(0);
  const [endConsultDialogShow, setendConsultDialogShow] = useState(false);
  const [message, setMessage] = useState('');

  const {
    consultationId,
    astrologerId,
    userId,
    astrologerName,
    endChatConsultationByMessageSent,
    chatStatus,
    start,
    cancelMins,
    sendInitMsg,
    setsendInitMsg,
    handleEndChatByAlert,
    userName,
    setchatStatus,
    user,
    appConstants,
  } = useContext(ConsultationContext) || {
    consultationId: "",
    astrologerId: "",
    userId: "",
    astrologerName: "",
    endChatConsultationByMessageSent: () => { },
    chatStatus: "",
    start: "",
    cancelMins: 2,
    sendInitMsg: "",
    setsendInitMsg: () => { },
    handleEndChatByAlert: () => { },
    userName: "",
    setchatStatus: () => { },
    user: {},
    appConstants: {},
  };
  const [formValue, setformValue] = useState("");
  const [consultationDetail, setConsultationDetail] = useState();
  const [startedByAstro, setStartedByAstro] = useState(false);
  const [messages] = useCollectionData(
    query(
      collection(db, process.env.GATSBY_FIREBASE_CHAT_COLLECTION),
      where("astrologerUserId", "==", astrologerId),
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      limit(500)
    )
  );
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave? Your chat session will be lost.";
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (consultationId) {
      localStorage.setItem("consultationId", consultationId);
    }
  }, [consultationId]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userId", userId);
    }
  }, [userId]);

  useEffect(() => {
    if (astrologerId) {
      localStorage.setItem("astrologerId", astrologerId);
    }
  }, [astrologerId]);

  useEffect(() => {
    if (window.innerWidth < 756) {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });

    return () => {
      window.removeEventListener("resize", () => { });
    };
  }, []);

  useEffect(() => {
    const fn = async () => {
      if (astrologerId) {
        let res = await getAstroDetail(astrologerId);
        if (res.statusCode === 200) {
          setdetail({ ...res.payLoad.astrologerDetail });
        }
      }
    };
    fn();
  }, [astrologerId]);

  useEffect(() => {
    if (messages?.length) {
      if (consultationDetail?.status === 'initiated') {
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].chartEnd == true) {
            break;
          }
          if (messages[i].fromCustomer === false
            && messages[i].messageText != "We are happy to inform you that the Astrologer is not charging you for the 1st minute of this chat."
            && messages[i].messageText != `Thank you for connecting with Astrologer ${astrologerName}. Please wait while he connects back. Your estimated wait time is 2 mins...`
          ) {
            setStartedByAstro(true)
          }
          if (messages[i].fromAstrologerChatStarted == true && chatStatus == "initiated") {
            setchatStatus("started");
          }
        }
      } else {
        if (messages[0].chartEnd == true) {
          setchatStatus("completed");
          navigate("/consultationEnd", {
            state: { id: consultationId },
            replace: true,
          });
        }
        for (let i = 0; i < messages.length; i++) {
          if (messages[i].fromAstrologerChatStarted == true) {
            break;
          }
          if (messages[i].chartEnd == true) {
            setchatStatus("completed");
            navigate("/consultationEnd", {
              state: { id: consultationId },
              replace: true,
            });
          }
        }
      }
    }
  }, [messages]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    kRef.current.focus();
    sendMessage(formValue);
    setformValue("");
    dummyRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const sendMessage = async (message, fromAstro) => {
    let ob = {
      userId: userId,
      astrologerUserId: astrologerId,
      timestamp: serverTimestamp(),
      fromCustomer: fromAstro == true ? false : true,
      fromAstrologerChatStarted: chatStatus == "started" ? true : false,
      messageText: message,
      chatId: consultationId,
      userFireBaseId: "",
    };
    await addDoc(
      collection(db, process.env.GATSBY_FIREBASE_CHAT_COLLECTION),
      ob
    );
    let res = await messageSent(consultationId, message);
    if (res.statusCode == 200) {
      if (res?.payLoad?.status == "completed") {
        endChatConsultationByMessageSent(consultationId, res?.payLoad?.message);
      }
    }
  };

  const sendEndChatMessage = async () => {
    if (chatStatus == "started") {
      let ob = {
        astrologerName: astrologerName,
        astrologerUserId: astrologerId,
        chartEnd: true,
        chartStart: false,
        chatId: consultationId,
        fromAstrologerChatStarted: false,
        fromCustomer: true,
        messageText: "",
        timestamp: serverTimestamp(),
        userFireBaseId: "",
        userId: userId,
      };

      await addDoc(
        collection(db, process.env.GATSBY_FIREBASE_CHAT_COLLECTION),
        ob
      );
    }
    await handleEndChatByAlert();
  };


  useEffect(() => {
    setConsultationDetail(JSON.parse(localStorage.getItem("consultationDetails")));
    const storedConsultationId = localStorage.getItem("consultationId");
    const storedUserId = localStorage.getItem("userId");
    const storedAstrologerId = localStorage.getItem("astrologerId");


    // Check if any of these values are missing, and if so, redirect appropriately
    if (!storedConsultationId || !storedUserId || !storedAstrologerId) {
      navigate("/consultAstro", { replace: true });
    }
    const initialSystemTime = Date.now();
    const initialPerformanceTime = performance.now();

    const createdAt = consultationDetail?.createdAt;
    const stat = new Date(createdAt).getTime();
    const consultationDuration = (consultationDetail?.maxDurationInMins || 0) * 60000;
    const futureDate = new Date(stat + consultationDuration - 1 * 60000);
    const futureTime = futureDate.getTime();

    let wasStartedByAstro = false;
    let secondsCounter = parseInt(localStorage.getItem('secondsCounter'), 10) || 59;

    const interval = setInterval(() => {
      const elapsedTime = performance.now() - initialPerformanceTime;
      const now = initialSystemTime + elapsedTime;

      let min;
      let distance;
      const st = new Date(start).getTime();

      console.log("chatStatus",chatStatus," run")
      if (chatStatus === "initiated" && !startedByAstro) {
        distance = st + cancelMins * 60000 - now;
        min = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
        secondsCounter = Math.floor((distance % (60 * 1000)) / 1000);
        setMessage('The Astrologer will join in less than');
      } else {
        console.log("elseif")
        setMessage('This chat will end in');
        distance = futureTime + cancelMins * 60000 - now;
        min = Math.floor(Math.abs(distance) / (60 * 1000));
        if (!wasStartedByAstro) {
          wasStartedByAstro = true;
        } else {
          if (secondsCounter === 0 && min > 0) {
            min--;
            secondsCounter = 59;
          } else {
            secondsCounter--;
          }
        }
      } 

      if (distance < 0) {
        clearInterval(interval);
        if ((chatStatus === "initiated" || chatStatus === "started") && start !== "") {
          setendConsultDialogShow(true);
        }
      } else {
        setm(min || 0);
        sets(secondsCounter || 0);
        localStorage.setItem('secondsCounter', secondsCounter); // Store the seconds counter in local storage
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      localStorage.setItem('secondsCounter', secondsCounter); // Store the seconds counter before unmount
    };
  }, [start, chatStatus, startedByAstro, consultationId, navigate]);


  useEffect(() => {
    if (sendInitMsg && astrologerName) {
      sendMessage(
        `Thank you for connecting with Astrologer ${astrologerName}. Please wait while he connects back. Your estimated wait time is ${cancelMins} mins...`,
        true
      );
      sendMessage(
        "We are happy to inform you that the Astrologer is not charging you for the 1st minute of this chat.",
        true
      );
      if (appConstants?.isUserDetailForChatActive) {
        sendMessage(
          `Name: ${userName};
        Time Of Birth: ${user?.timeOfBirth};
        DOB: ${user?.dob} Place Of Birth: ${user?.placeOfBirth};
        Latitude: ${user?.latitude};
        Longitude: ${user?.longitude}`,
          true
        );
      }
      setsendInitMsg(false);
    }
  }, [astrologerName, sendInitMsg]);

  const handleWait = () => {
    setendConsultDialogShow(false);
  };

  useEffect(() => {
    if (chatStatus == "started") {
      window.addEventListener("beforeunload", alertUser);
    } else {
      window.removeEventListener("beforeunload", alertUser);
    }

    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [chatStatus]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  if (!consultationId) {
    return <Spinner />;
  }





  return (
    <>
      <div className=" worksans">
        {endConsultDialogShow &&
          (chatStatus == "initiated" ? (
            <ConsultAlertModal
              data={{
                message: `Hey ${userName}, we are trying to connect you to Astrologer ${astrologerName} but its taking some time! However it will be worth the wait once you are connected!\n\nDo you want to continue or would you like to connect to another astrologer?`,
                handleWait,
                modalAction: handleEndChatByAlert,
                buttonText1: "Other Options",
                buttonText2: "Wait",
              }}
            />
          ) : (
            <ConsultAlertModal
              data={{
                message: `Want to end chat?`,
                handleWait,
                modalAction: sendEndChatMessage,
                buttonText1: "Yes, I want to end the chat",
                buttonText2: "No, I want to continue the chat",
              }}
            />
          ))}

        <div className="mmm flex flex-col block hide-scroll-bar scrollbar-hide">
          {/* <Header /> */}
          <main className="flex-1 overflow-y-auto flex z-40">
            <div className="w-0 md:w-1/3 lg:w-1/2 flex flex-col items-center p-0 md:p-10">
              <div className="w-full lg:mx-10 md:rounded-xl overflow-hidden md:border">
                <div className="relative block h-[27rem]">
                  <div className="bg-gradient-to-r from-[#ff4c41]/20 to-orange-500/50 h-44"></div>

                  <div className="absolute top-32 w-full flex justify-center">
                    <div className="w-10/12 bg-white rounded-xl shadow flex flex-col items-center">
                      <h1 className="mt-20 font-bold text-xl text-gray-800">
                        {detail?.name}
                      </h1>
                      <div className="w-full flex flex-col">
                        <p className="text-gray-500 text-center">
                          {detail?.subHeading}
                        </p>
                      </div>
                      <div className="w-full flex flex-col">
                        <h1 className="text-gray-500 text-center">
                          {"Languages: " + processLanguage(detail?.languages)}
                        </h1>
                      </div>
                      <div className="flex m-2 justify-around w-full flex-wrap">
                        {detail?.category?.map((v, i) => {
                          return (
                            <div
                              className={
                                "rounded-xl border m-1 py-1 px-2 border-orange-600"
                              }
                              key={i}
                            >
                              <h1 className={"text-xs text-orange-600"}>
                                {capitalize(v)}
                              </h1>
                            </div>
                          );
                        })}
                      </div>
                      <div className="flex flex-1 bg-red-50 justify-around w-full items-center rounded-b-xl py-2">
                        <div className="flex flex-col items-center">
                          <h1 className="font-semibold">
                            {detail?.experience?.years + " years+"}
                          </h1>
                          <h2 className="text-gray-700">Experience</h2>
                        </div>
                        <div className="flex flex-col items-center">
                          <h1 className="font-semibold">
                            {detail?.consultationsDisplayCount}
                          </h1>
                          <h2 className="text-gray-700">Consultations</h2>
                        </div>
                        <div className="flex flex-col items-center">
                          <h1 className="font-semibold">{detail.rating}</h1>
                          <h2 className="text-gray-700">Rating</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="absolute top-16 w-full flex justify-center">
                    {detail?.imgUrl ? (
                      <img
                        loading="lazy"
                        src={detail?.imgUrl}
                        className="object-contain rounded-full h-36 w-36 bg-gray-100 border border-white shadow-lg"
                        alt={detail?.name}
                        title={detail?.name}
                      />
                    ) : (
                      <img
                        loading="lazy"
                        src={i}
                        className="object-contain rounded-full h-36 w-36 bg-gray-100 border border-white shadow-lg"
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-auto md:w-2/3 lg:w-1/2 flex flex-col md:py-10 md:pr-10 md:pl-2">
              <div className="flex flex-col md:rounded-xl h-full md:border">
                <div className="relative md:rounded-xl flex flex-col">
                  <div
                    className={`${consultationDetail?.status != 'initiated'
                      ? "opacity-0 h-0"
                      : "opacity-100 h-auto"
                      } transition-opacity duration-200 ease-in-out glassy-bg absolute -bottom-8 md:-bottom-10 w-full z-50`}
                  >
                    {
                      message ? <h2 className="text-center text-sm md:text-base py-2">{`${message} : ${m} min : ${s} sec`}</h2>
                        : ''
                    }
                  </div>
                  <div className="h-16 md:h-20 bg-gradient-to-r from-[#ff4c41]/20 to-orange-500/50 grid grid-cols-3 justify-items-stretch  items-center w-full md:rounded-t-xl">
                    <button
                      onClick={() => {
                        setendConsultDialogShow(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 ml-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    <div className="font-semibold text-base md:text-lg text-black text-center">
                      {astrologerName}
                    </div>
                    <button
                      className="rounded-xl bg-[#E1653E] px-3 md:px-4 py-1 text-sm md:text-base text-white mr-4 justify-self-end"
                      onClick={() => {
                        setendConsultDialogShow(true);
                      }}
                    >
                      End
                    </button>
                  </div>
                </div>
                <div
                  ref={dummyRef}
                  className="flex-1 overflow-y-auto flex flex-col-reverse z-40 bg-[#FAF5EE]"
                >
                  {/* <div
                    className={`${
                      chatStatus == "initiated" ? "opacity-0" : "opacity-100"
                    } transition-opacity duration-200 ease-in-out absolute top-16 md:top-28 right-0 glassy-bg w-full md:w-2/3 lg:w-1/2`}
                  >
                    <h2 className="text-center text-sm md:text-base py-2">{`Duration: ${m} min : ${s} sec`}</h2>
                  </div> */}
                  {(messages ?? []).map((m, i) => {
                    if (m.fromCustomer && m.messageText) {
                      return <UserMessage key={i} data={m} />;
                    } else if (m.messageText) {
                      return <AstroMessage key={i} data={m} />;
                    } else {
                      return <></>;
                    }
                  })}
                </div>
                <div className="bg-white h-16 flex items-center shad text-base md:rounded-b-xl">
                  <form className="h-16 w-screen flex items-center">
                    <div className="w-vw flex-1 rounded-full overflow-hidden bg-white mx-2">
                      <input
                        ref={kRef}
                        className="w-full appearance-none border-none text-gray-700 py-1 px-2 leading-tight focus:outline-none ml-2"
                        type="text"
                        placeholder="Write your message..."
                        aria-label="chat-message"
                        value={formValue}
                        onChange={(e) => {
                          setformValue(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      className="rounded-full bg-orange-500 p-2 mr-2"
                      onClick={handleSubmit}
                    >
                      <img loading="lazy" src={Send} className="w-4 h-4 object-contain" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-orange-500"></div>
    </div>
  );
};
export default Chat;
